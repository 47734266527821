






























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ActionMethod } from 'vuex';
import { Action, Getter } from 'vuex-class';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import DeletionConfirmation
  from '@improve/common-components/src/components/widgets/DeletionConfirmation.vue';
import BaseTicket from '@/components/ticket/BaseTicket.vue';
import BaseTicketActions
  from '@improve/common-components/src/components/ticket/BaseTicketActions.vue';
import Ticket from '@improve/common-utils/src/model/Ticket';
import User from '@improve/common-utils/src/model/User';
import ImproveTicketStatus from '@improve/common-utils/src/types/ImproveTicketStatus';
import TicketDelegationType from '@improve/common-utils/src/types/TicketDelegationType';
import TaskUpdateParams from '@improve/common-utils/src/types/TaskUpdateParams';

@Component({
  name: 'EditTicket',
  components: {
    BaseTicket,
    BaseButton,
    BaseTicketActions,
    DeletionConfirmation
  }
})
export default class EditTicket extends Vue {
  @Getter currentUser?: User;

  @Action getTicketById!: ActionMethod;

  @Action createTicket!: ActionMethod;

  @Action updateTicket!: ActionMethod;

  @Action updateTasks!: ActionMethod;

  @Action updateTicketStatus!: ActionMethod;

  readonly assignOptions: Array<{ label: string; value: string }> = [
    {
      label: this.$t('form.ticketAssignToTeam').toString(),
      value: TicketDelegationType.TEAM
    },
    {
      label: this.$t('form.ticketAssignToTopic').toString(),
      value: TicketDelegationType.TOPIC
    }
  ];

  ticketStatus = ImproveTicketStatus;

  ticket: Ticket | null = null;

  isSaving = false;

  askArchiveConfirmation = false;

  get userFullName(): string {
    return `${this.currentUser?.firstName} ${this.currentUser?.lastName}`;
  }

  get baseTicket(): any {
    return this.$refs.baseTicket as any;
  }

  get canArchiveTicket(): boolean {
    return !!(this.ticket
      && this.ticket.status !== ImproveTicketStatus.ARCHIVED
      && this.ticket.status !== ImproveTicketStatus.DRAFT);
  }

  async created(): Promise<void> {
    await this.fetchTicketById();
  }

  fetchTicketById(): Promise<void> {
    const tId = this.$route.params.id;
    return this.getTicketById(tId)
      .then((ticket) => {
        this.ticket = ticket;
      })
      .catch(() => {
        this.goToImprove();
      });
  }

  async update(ticket: Ticket): Promise<void> {
    this.ticket = ticket;
    this.isSaving = true;
    await this.updateTicket(this.ticket);
    await this.updateTicketTasks();
    this.isSaving = false;
  }

  async save(): Promise<void> {
    const valid = await this.baseTicket.validate();
    if (!valid) {
      return;
    }
    this.isSaving = true;
    await this.updateTicket(this.baseTicket.prepareTicket());
    await this.updateTicketTasks();
    this.isSaving = false;

    this.goToImprove();
  }

  async submitForReview(): Promise<void> {
    const valid = await this.baseTicket.validate();
    if (!valid) {
      return;
    }
    this.isSaving = true;
    await this.updateTicketStatus({
      ticket: this.ticket,
      fromStatus: this.ticket!.status,
      toStatus: ImproveTicketStatus.READY_TO_REVIEW
    });
    await this.updateTicketTasks();
    this.isSaving = false;

    if (this.baseTicket.ticket.status === ImproveTicketStatus.DRAFT) {
      this.goToThankYou(this.baseTicket.ticket.canonicalId);
    } else {
      this.goToImprove();
    }
  }

  async archiveTicket(): Promise<void> {
    this.isSaving = true;
    await this.updateTicketStatus({
      ticket: this.ticket,
      fromStatus: this.ticket!.status,
      toStatus: ImproveTicketStatus.ARCHIVED
    });
    this.isSaving = false;
    this.goToMyImproves();
  }

  async updateTicketTasks(): Promise<TaskUpdateParams> {
    const tasks: TaskUpdateParams = this.baseTicket.prepareTasks();
    await this.updateTasks({
      parent: this.ticket,
      updates: tasks
    });
    return Promise.resolve(tasks);
  }

  goToMyImproves(): void {
    this.$router.push({ name: 'MyImprovesTab' });
  }

  goToImprove(): void {
    this.$router.push({ name: 'TicketDetails', params: { id: this.$route.params.id } });
  }

  goToThankYou(ticketId: string): void {
    this.$router.push({
      name: 'TicketThanks',
      params: { id: ticketId }
    });
  }
}

